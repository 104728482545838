<template>
   <div class="notice_popup prj_itv" :style="{height: step == 1 ? undefined : '550px' }">
        <div class="close">
            <img src="/images/close.png" alt="닫기" @click="$emit('close', false)"/>
        </div>
        <img src="/images/interview.png" alt="프로젝트인터뷰"/><div class="tit">프로젝트 인터뷰 요청하기</div>
        <template v-if="step == 1">
            <div class="input-box">
                <p>프로젝트 선택</p>
                <SelectComp type="select" title="프로젝트 선택" v-model="projectSeq" :list="projectList" codeKey="projectSeq" nameKey="projectNm" @change="changeProject" v-slot="slot">
                    {{slot.item.projectNm}}
                    ({{slot.item.projectStartYyyymmdd | date('yyyy.MM.dd')}} ~ {{slot.item.projectEndYyyymmdd | date('yyyy.MM.dd')}})
                    <template v-if="slot.item.interviewStatusCd">[{{slot.item.interviewStatusCd == '04' ? '합격' : '인터뷰중'}}]</template>
                </SelectComp>
            </div>
            <div class="input-box">
                <p>모집분야</p>
                <SelectComp type="select" title="모집분야 선택" v-model="projectRecrFieldSeq" :list="projectRecrFieldList" codeKey="projectRecrFieldSeq" nameKey="workCont" v-slot="slot" @change="changeProjectRecrField">
                    {{slot.item.workCont}} ({{slot.item.joinTermStartYyyymmdd | date('yyyy.MM.dd')}} ~ {{slot.item.joinTermEndYyyymmdd | date('yyyy.MM.dd')}})
                </SelectComp>
            </div>
            <div class="member">프로젝트 인터뷰 요청할 기술인재<tec-summary tagName="span" v-model="tecInfo" :tecMberSeq="param.tecMberSeq"/></div>
            <div class="select-btn">
                <div class="btn" @click="$emit('close', false)">취소</div>
                <div class="btn" @click="next()">다음 &#9654;</div>
            </div>
        </template>
        <template v-else>
            <div class="prj_outline">
                <div class="prj_name">{{projectInfo.projectNm}}</div>
                <div class="outline" style="display: inline-flex; width: 580px;">
                    <div class="label ivl02" style="min-width: 66px;">수 행 사</div>                    
                    <div style="display: inline-flex; align-items: center;">
                        <div class="value" style="white-space: nowrap;">{{projectInfo.corpNm}}</div>                                               
                        <div class="value location">
                            <img src="/images/location.png" alt="위치"/>{{projectInfo.projectLocCont}}
                        </div>                        
                    </div>
                </div>
                <div class="outline">
                    <div class="label ivl02">담 당 자</div><div class="value">{{projectInfo.corpMgrMberNm}}</div>
                </div>
                <div class="outline">
                    <div class="label">참 여 기 간</div><div class="value">{{projectRecrField.joinTermStartYyyymmdd | date('yyyy.MM.dd')}} - {{projectRecrField.joinTermEndYyyymmdd | date('yyyy.MM.dd')}}</div>
                </div>
                <div class="outline">
                    <div class="label">모 집 분 야</div><div class="value">{{projectRecrField.workCont }}</div>
                </div>
                <div class="outline">
                    <div class="label ivl03" style="margin-right: -3px;">개요</div><div class="value text">{{projectInfo.projectDtlDesc}}</div>
                </div>
            </div>
            <div class="member" style="margin-left: -10px;"><tec-summary tagName="span" v-model="tecInfo"/> 님에게 위 프로젝트에 대한 인터뷰를 요청 하시겠습니까?</div>
            <div class="select-btn">
                <div class="btn" @click="step = 1">&#9664; 이전</div>
                <div class="btn" @click="save()">요청완료</div>
            </div>
        </template>
    </div>
</template>

<script>
import tecSummary from "@/components/highpro/TecSummary.vue";
export default {
    components: { tecSummary },
    props: ['param'],
    data() {
        return {
            step: 1,
            tecInfo : {},
            projectSeq: '', 
            projectRecrFieldSeq: '', 
            projectInfo: { },
            projectRecrField: { },
            projectList: [],
            projectRecrFieldList: [],
        };
    }, 
    mounted(){
        if(!this.param.tecMberSeq){
            alert('파라미터를 확인해주세요.');
            this.$emit('close', false);
            return
        }

        var param = {
            tecMberSeq: this.param.tecMberSeq,
            projectStatus : ['0'],
            orderGubun : '1',
            // projectGubun : '1',

        };
        this.$.httpPost('/api/prj/getProjectList', param)
			.then(res => {
				this.projectList = res.data.list;
			});
    },
    methods: {
        changeProject(event, val, item){
            //console.log('changeProject', event, val, item);
            this.projectRecrFieldSeq = '';
            this.projectRecrFieldList = [];
            if(item.interviewStatusCd){
                this.projectSeq = '';
                alert('이미 ' + (item.interviewStatusCd == '04' ? '합격 된' : '인터뷰중 인') + ' 프로젝트 입니다.');
                return false;
            }
            this.projectInfo = item || {};
            if(this.projectInfo && this.projectInfo.projectSeq){
                this.$.httpPost('/api/prj/getProjectRecrFieldList', { tecMberSeq : this.param.tecMberSeq, projectSeq : this.projectInfo.projectSeq })
                    .then(res => {
                        this.projectRecrFieldList = res.data.list;
                    });
            }
        },
        changeProjectRecrField(event, val, item){
            //console.log('changeProjectRecrField', event, val, item);
            this.projectRecrField = item || {};
        },
        next(){
            //console.log('next', this.projectInfo, this.projectRecrField);
            if(!this.projectSeq || this.projectSeq != this.projectInfo.projectSeq){
                return alert('프로젝트를 선택해 주세요.');
            }
            if(!this.projectRecrFieldSeq || this.projectRecrFieldSeq != this.projectRecrField.projectRecrFieldSeq){
                return alert('모집분야를 선택해 주세요.');
            }
            this.step = 2;
        },
        save(){
            if(!this.projectSeq || this.projectSeq != this.projectInfo.projectSeq){
                this.step = 1;
                return alert('프로젝트를 선택해 주세요.');
            }
            if(!this.projectRecrFieldSeq || this.projectRecrFieldSeq != this.projectRecrField.projectRecrFieldSeq){
                this.step = 1;
                return alert('모집분야를 선택해 주세요.');
            }
            
            this.$.httpPost('/api/prj/dco/pmg/reqInterview', { tecMberSeq : this.param.tecMberSeq, projectRecrFieldSeq : this.projectRecrFieldSeq })
                .then(() => {
                    alert('인터뷰 요청이 완료되었습니다.');
                    this.$emit('close', {projectRecrFieldSeq : this.projectRecrFieldSeq});
                })
                .catch(err => {
                    if(err.response && err.response.data && err.response.data.error_description){
                        alert(err.response.data.error_description);
                    } else {
                        alert('인터뷰 요청에 실패했습니다.')
                        // console.error(err);
                    }
                });
        }
    },
};
</script>